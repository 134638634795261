<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" is-full-page :zIndex="2147483639"></loading>
        <div v-if="!isPageLoading">

          <div class="page-title">
            <h1>Project Form</h1>
          </div>

          <div class="page-content">

            <div class="alert alert-warning mb-5" v-if="projectData.isPrivate">
              <i class="fas fa-exclamation-triangle mr-2"></i>
              This is a private project and can only be seen by those who are invited.
            </div>

            <form v-on:submit.prevent="onSubmit">
              <div v-if="isEdit">
                <div v-if="showSendToBCBid && getProject.sentReportToBCBid != null" class="row d-flex justify-content-end">
                  <strong class="mr-1">Last Sent:</strong><br /><span class="mr-1">{{getProject.sentReportToBCBid.occurrence | datetime}}</span>
                </div>
                <div class="row d-flex justify-content-end">
                  <menu class="d-flex align-items-center justify-content-end m-0">
                    <button type="button" class="btn btn-secondary p-2 text-dark ml-2 px-3 py-2 rounded"
                            :disabled="!projectData.followers || !projectData.followers.length" @click="emailFollowers">
                      <i class="far fa-envelope"></i>
                      Email Followers
                    </button>
                    <router-link :to="'/project/info/' + this.projectData.id" class="btn btn-secondary p-2 text-dark ml-2 px-3 py-2 rounded" title="View Project">
                      <i class="far fa-eye"></i>
                      Member View
                    </router-link>
                    <button v-if="showSendToBCBid" type="button" class="btn btn-secondary p-2 text-dark ml-2 px-3 py-2 rounded" :class="{ 'running' : isSendingToBCBid }"
                        :disabled="!canSendToBCBid || isSendingToBCBid" @click="sendToBCBid">
                      <i v-if="!isSendingToBCBid" class="fas fa-share-square"></i>
                      <div v-else class="ld ld-ring ld-spin"></div>

                      <span class="ml-2">{{ !isSendingToBCBid ? "Send to BC Bid" : "Sending -- Please wait"}}</span>
                    </button>
                  </menu>
                </div>


              </div>

              <div class="alert alert-danger alert-validation-summary-errors" v-if="errors.any()">
                <button type="button" class="close" data-dismiss="alert">×</button>
                <strong class="alert-heading">Please review the following errors</strong>
                <ul>
                  <li v-for="error in errors.all()" :key="error" class="error-message">{{ error }}</li>
                </ul>
              </div>

              <project-step-identification :project.sync="projectData" :zones="zones"></project-step-identification>
              <hr />

              <project-step-details :project.sync="projectData" :provinces="provinces" :selectedTimeZone="getSelectedTimeZone"></project-step-details>
              <hr />

              <project-step-description :project.sync="projectData"></project-step-description>
              <hr />

              <project-step-contacts :project.sync="projectData"></project-step-contacts>
              <hr />

              <project-step-documents :isNewProject="!isEdit" :project="projectData" :rootFolderId="projectData.rootFolderId"></project-step-documents>
              <hr />

              <project-step-notes :project.sync="projectData"></project-step-notes>
              <hr />

              <project-step-tasks :selectedTimeZone="getSelectedTimeZone"></project-step-tasks>
              <hr />
            </form>

            <div class="mt-3 mb-3 d-flex ">
              <div class="flex-fill" v-if="projectData.createdOn || projectData.updatedOn">
                <div class="d-flex flex-column mb-3">
                  <div>Created By: {{ projectData.createdBy | fullname }}</div>
                  <div>Created On: {{ projectData.createdOn | datetime('MMMM dd, yyyy h:mmaa') }} <span v-if="projectData.createdOn">{{ this.getClientTimeZone.code }}</span></div>
                  <div>Last Updated By: {{ projectData.updatedBy | fullname }}</div>
                  <div>Last Updated On: {{ projectData.updatedOn | datetime('MMMM dd, yyyy h:mmaa') }} <span v-if="projectData.updatedOn">{{ this.getClientTimeZone.code }}</span></div>
                </div>
              </div>
              <div class="p-2 flex-fill ">&nbsp;</div>
              <div class="p-2 flex-fill ">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="save-bar-wrapper" class="d-print-none">
      <div>
        <button type="submit" class="save-bar-Button ld-ext-right" :class="{ 'running' : isSaving }" @click="onSubmit()" :disabled="isSaving">
          <span v-if="isSaving">Saving...</span>
          <span v-else>Save</span>
          <div class="ld ld-ring ld-spin"></div>
        </button>

        <button type="submit" class="save-bar-Button ml-2" @click="goToTop()">
          <i class="fas fa-arrow-alt-circle-up"></i>
          Back to Top
        </button>
      </div>
    </div>

    <b-modal id="bv-modal-email-followers" size="lg" hide-header hide-footer :no-close-on-backdrop="true" :no-enforce-focus="true">
      <app-send-email target-modal="bv-modal-email-followers"
                      :records="projectFollowers"
                      :autoSelect="true"
                      :subject="defaultFollowerEmailSubjectText"
                      :body="defaultFollowerEmailBodyText"
                      :enable-include-me="true">
      </app-send-email>
    </b-modal>
  </fragment>
</template>

<script>
  import AppNavBar from "@/components/app-navbar.vue";
  import ProjectStepIdentification from "@/views/project/components/project-step-identification.vue";
  import ProjectStepDetails from "@/views/project/components/project-step-details.vue";
  import ProjectStepDescription from "@/views/project/components/project-step-description.vue";
  import ProjectStepContacts from "@/views/project/components/project-step-contacts.vue";
  import ProjectStepDocuments from "@/views/project/components/project-step-documents.vue";
  import ProjectStepNotes from "@/views/project/components/project-step-notes.vue";
  import ProjectStepTasks from "@/views/project/components/project-step-tasks.vue";
  import AppSendEmail from "@/components/modals/app-send-email.vue";

  import { mapGetters, mapActions } from "vuex";
  import { ProjectService } from "@/services/";
  import Toast from "@/utils/toast";

  export default {
    components: {
      "app-navbar": AppNavBar,
      ProjectStepIdentification,
      ProjectStepDetails,
      ProjectStepDescription,
      ProjectStepContacts,
      ProjectStepDocuments,
      ProjectStepNotes,
      ProjectStepTasks,
      AppSendEmail
    },
    provide() {
      return {
        $validator: this.$validator
      };
    },
    data: function () {
      return {
        isSaving: false,
        isPageLoading: true,
        isEdit: false,
        projectData: {
          shareWithZones: [],
          classificationOfWorks: [],
          ShowProject: false,
          TradeAgreementIds: []
        },
        participantTypes: [],
        classificationTypes: [],
        contactTypes: [],
        fundingTypes: [],
        ownerTypes: [],
        procurementTypes: [],
        provinces: [],
        tenderStages: [],
        tradeAgreements: [],
        zones: [],
        projectFollowers: [],
        defaultFollowerEmailSubjectText: null,
        defaultFollowerEmailBodyText: null,
        timeZones: [],
        isSendingToBCBid: false
      };
    },
    computed: {
      canSendToBCBid () {
        return Date.now() < Date.parse(this.projectData.closingDate);
      },
      getSelectedTimeZone() {
        let selectedTimeZoneId = this.projectData.timeZone.id || this.getClientTimeZone.id;
        return this.timeZones.find(t => t.id === selectedTimeZoneId);
      },
      showSendToBCBid() {
        return (this.getUseSendToBCBid || '').toUpperCase() === 'TRUE' && (this.$permissions().isSysAdmin || this.$permissions().isClientAdmin);
      },
      ...mapGetters(["getProject", "getClientTimeZone", "getCurrentUser", "getUseSendToBCBid"])
    },
    methods: {
      ...mapActions([
        "fetchClientSettings",
        "fetchProject",
        "fetchLookup",
        "fetchContactTypes",
        "fetchFundingTypes",
        "fetchZones",
        "fetchParticipantTypes",
        "fetchProvinces",
        "fetchTenderStages",
        "fetchProcurementTypes",
        "fetchOwners",
        "fetchOwnersByProject",
        "fetchOwnerTypes",
        "fetchClassificationTypes",
        "fetchTradeAgreements",
        "saveProject",
        "fetchTimeZones",
        "fetchUser"
      ]),
      emailFollowers() {
        this.$bvModal.show("bv-modal-email-followers");
      },
      goToTop() {
        window.scrollTo(0, 0);
      },
      async onSubmit() {
        var isFormValid = await this.$validator.validate();

        if (!isFormValid) {
          window.scrollTo(0, 0);
          return;
        }

        if (this.projectData.isPrivate){
          this.projectData.showProject = true;
        }

        this.isSaving = true;

        this.saveProject({ data: this.projectData, isEdit: this.isEdit })
          .then(project => {
            this.projectData = project;
            Toast.success(this, "Successfully saved!");
            this.isSaving = false;

            if (this.isEdit === false) {
              this.$router.push("/project/edit/" + project.id);
            }
          })
          .catch(() => {
            Toast.danger(this, "Oops! There was an error.");
            this.isSaving = false;
          });
      },
      sendToBCBid() {
        this.isSendingToBCBid = true;
        ProjectService.sendToBCBid(this.$route.params.id)
          .then(() => {
            this.isSendingToBCBid = false;
            Toast.success(this, "This project's data has been sent to BC Bid.");
          })
          .catch(() => {
            this.isSendingToBCBid = false;
            Toast.danger(this, "Oops! There was an error.");
          });
      }
    },
    mounted: function () {
      this.isEdit = !!this.$route.params.id;

      Promise.all([
        Promise.resolve({}),//this.fetchLookup(),
        this.fetchClassificationTypes(),
        this.fetchClientSettings(),
        this.fetchContactTypes(),
        this.fetchFundingTypes(),
        Promise.resolve({}), //this.$route.params.id ? this.fetchOwnersByProject(this.$route.params.id) : this.fetchOwners(),
        this.fetchOwnerTypes(),
        this.fetchParticipantTypes(),
        this.fetchProcurementTypes(),
        this.fetchProvinces(),
        this.fetchTenderStages(),
        this.fetchTradeAgreements(),
        this.fetchZones(),
        this.fetchProject(this.$route.params.id),
        this.fetchTimeZones(),
        this.fetchUser(this.getCurrentUser.id)
      ])
        .then(result => {
          //this.companyLookup = result[0];
          this.classificationTypes = result[1];
          this.contactTypes = result[3];
          this.fundingTypes = result[4];
          //this.owners = result[5];
          this.ownerTypes = result[6];
          this.participantTypes = result[7];
          this.procurementTypes = result[8];
          this.provinces = result[9];
          this.tenderStages = result[10];
          this.tradeAgreements = result[11];
          this.zones = result[12];
          this.timeZones = result[14];

          let user = result[15];
          if (!user.isClientAdmin && !user.isSystemAdmin) {
            let membershipZones = user.companies.map(function (e) { return e.memberships.map(({ zoneId }) => zoneId); }).flat();
            this.zones = this.zones.filter(r => membershipZones.indexOf(r.id) > -1);
          }

          this.projectData = this.getProject;
          this.isPageLoading = false;
          this.projectFollowers = this.projectData.followers.map(follower => ({ name: `${follower.firstName} ${follower.lastName}`, email: follower.email }));

          this.defaultFollowerEmailSubjectText = 'Project Update: ' + this.projectData.ocaaNumber + ' - ' + this.projectData.title;
          this.defaultFollowerEmailBodyText = `<p><a href="${document.location.origin}/project/info/${this.projectData.id}">Link to Project</a></p>`;
        })
        .catch(err => {
          if (err && err.response && err.response.data && err.response.data.message && err.response.data.message === 'The request is invalid.') {
            let message = this.$createElement('span', {}, ['Oops! You managed to get to an area you do not have permission to visit', this.$createElement('br'), 'Redirecting back to dashboard']);
            Toast.danger(this, [message], { autoHideDelay: 7500 });

            this.$router.push('/dashboard');
            return;
          }

          this.isPageLoading = false;
          let message = this.$createElement('span', {}, ['Oops! An error has occured and some data could not be loaded', this.$createElement('br'), 'Please contact an administrator']);
          Toast.danger(this, [message], { autoHideDelay: 7500 });
        });
    }
  };
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row form-title d-flex align-items-center mb-4">
        <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">2</span>
        <h2 class="m-0">Project Details</h2>
      </div>

      <project-addresses></project-addresses>

      <div class="form-row">
        <div class="form-group col-lg-4">
          <label for="closingDate">Closing Date <span class="red-dot ml-3" v-if="!isPreBid"></span>
          </label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text bg-transparent pr-2">
                <img src="@/assets/cal.svg" alt />
              </span>
            </div>
            <date-picker id="closingDate" name="closingDate" class="form-control date-picker-input" :class="{ 'is-invalid': errors.has('closingDate') }"
              autocomplete="off" v-model="project.closingDate" :config="datepickerOptionsClosingDate" v-validate="{ required: !isPreBid }"
              :readonly="project.fromBids" data-vv-as="Closing Date">
            </date-picker>
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">{{ selectedTimeZone.code }}</span>
            </div>
            <div class="invalid-feedback">The Closing Date field is required</div>
          </div>
        </div>

        <div class="form-group col-lg-4">
          <label for="closingDate">Other Closing Date</label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text bg-transparent pr-2">
                <img src="@/assets/cal.svg" alt />
              </span>
            </div>
            <date-picker id="otherClosingDate" name="otherClosingDate" class="form-control date-picker-input" autocomplete="off"
              v-model="project.otherClosingDate" :config="datepickerOptionsOtherClosingDate">
            </date-picker>
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">{{ selectedTimeZone.code }}</span>
            </div>
          </div>
        </div>

        <div class="form-group col-lg-4">
          <label for="awardedDate">Awarded Date</label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text bg-transparent pr-2">
                <img src="@/assets/cal.svg" alt />
              </span>
            </div>
            <date-picker id="awardedDate" name="awardedDate" class="form-control date-picker-input" autocomplete="off"
              v-model="project.awardedDate" :config="datepickerOptionsAwardedDate">
            </date-picker>
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">{{ selectedTimeZone.code }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="registerUrl">Register Url</label>
        <input id="registerUrl" name="registerUrl" type="text" class="form-control" :class="{ 'is-invalid': errors.has('registerUrl') }" autocomplete="off"
          placeholder="Enter register url" v-validate="'url'" v-model="project.registerUrl" data-vv-as="Register Url" />
        <div class="invalid-feedback">The Register URL is not a valid URL</div>
      </div>

      <div class="form-group">
        <label for="submitBidsTo">Submit Bids To <span class="red-dot ml-3" v-if="requiredForOpenProject"></span></label>
        <input id="submitBidsTo" name="submitBidsTo" type="text" class="form-control" :class="{ 'is-invalid': errors.has('submitBidsTo') }" autocomplete="off"
          placeholder="Enter where to submit bids to" v-validate="{ required: requiredForOpenProject }" v-model="project.submitBidsTo"
          :readonly="project.fromBids" data-vv-as="Submit Bids To" />
        <div class="invalid-feedback">You must enter information in the field for where to submit bids</div>
      </div>

      <div class="form-group">
        <label for="obtainBidDocuments">Obtain Bid Documents <span class="red-dot ml-3" v-if="requiredForOpenProject"></span></label>
        <input id="obtainBidDocuments" name="obtainBidDocuments" type="text" class="form-control" :class="{ 'is-invalid': errors.has('obtainBidDocuments') }"
          autocomplete="off" placeholder="Enter text" v-validate="{ required: requiredForOpenProject }" v-model="project.obtainBidDocuments"
          :readonly="project.fromBids" data-vv-as="Obtain Bids Documents" />
        <div class="invalid-feedback">{{ errors.first('obtainBidDocuments') }}</div>
      </div>

      <div class="form-group">
        <label for="bonding">Bonding</label>
        <input id="bonding" name="bonding" type="text" class="form-control" autocomplete="off" placeholder="Enter bonding" v-model="project.bonding" />
      </div>

      <project-site-meetings :selectedTimeZone="selectedTimeZone"></project-site-meetings>

      <div class="form-group">
        <label for="addenda">Addenda <span class="red-dot ml-3" v-if="requiredForOpenProject"></span></label>
        <input id="addenda" name="addenda" type="number" class="form-control" :class="{ 'is-invalid': errors.has('addenda') }" autocomplete="off"
          placeholder="Enter the number of addenda" v-validate="{ required: requiredForOpenProject }" v-model="project.addenda"
          :readonly="project.fromBids" data-vv-as="Addenda" />
        <div class="invalid-feedback">{{ errors.first('addenda') }}</div>
      </div>

      <div class="form-group">
        <label for="addendaNotes">Addenda Notes</label>
        <textarea id="addendaNotes" name="addendaNotes" class="form-control" placeholder="Enter additional addenda notes"
          rows="5" v-model="project.addendaInfo"></textarea>
      </div>

    </div>
    <div class="col-md d-none d-lg-block"></div>
  </div>
</template>

<script>
import { isDraftMode, isPreBid, isPrivateProject, requiredForOpenProject } from "@/mixins/projectStatuses";

import DatePicker from "vue-bootstrap-datetimepicker";
import ProjectAddresses from "@/views/project/components/project-addresses.vue";
import ProjectSiteMeetings from "@/views/project/components/project-site-meetings.vue";

import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "ProjectDetails",
  components: { DatePicker, ProjectAddresses, ProjectSiteMeetings },
  inject: ["$validator"],
  props: ["project", "provinces", "selectedTimeZone"],
  mixins: [isDraftMode, isPreBid, isPrivateProject, requiredForOpenProject],
  data: function() {
    return {
      datepickerOptionsClosingDate: {
        sideBySide: true,
        useCurrent: false,
        date: this.project.closingDate
      },
      datepickerOptionsOtherClosingDate: {
        sideBySide: true,
        useCurrent: false,
        date: this.project.otherClosingDate
      },
      datepickerOptionsAwardedDate: {
        sideBySide: true,
        useCurrent: false,
        date: this.project.awardedDate
      }
    };
  }
};
</script>

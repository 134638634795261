<template>
  <fragment>
    <form @submit.prevent>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">New Project Bidder</h5>
      </div>

      <div class="modal-body">

        <div class="form-group">
          <label for="companyLookup">
            Find A Company
          </label>
          <vue-bootstrap-typeahead
            id="companyLookup"
            name="companyLookup"
            v-model="query"
            :data="companies"
            :serializer="company => company.name"
            placeholder="Type a minimum 3 characters to start the search"
            :minMatchingChars="3"
            @hit="selectCompany($event)"
            ref="companyLookup"
          >
            <template slot="append">
              <button class="btn btn-outline-danger" type="button" name="Clear Company" title="Clear Company" @click="clearCompany()">
                <i class="fas fa-times-circle"></i>
              </button>
            </template>

            <template slot="append">
              <button class="btn btn-outline-info" type="button" name="New Company" title="New Company" @click="newCompany()">
                <i class="fas fa-user-plus"></i>
              </button>
            </template>

            <template slot="suggestion" slot-scope="{ data }">
              <strong>{{ data.name }}</strong>
              <br />
              {{ getAddressDisplay(data.address) }}
              <span v-if="data.phone">
                <br />
                {{ data.phone }}
              </span>
            </template>
          </vue-bootstrap-typeahead>
          <input type="hidden" id="hasCompany" name="hasCompany" v-validate="'required:true'" v-model="hasCompany" class="form-control"
            :class="{ 'is-invalid': !isNew && errors.has('hasCompany') }" />
          <div class="invalid-feedback">Please select a company</div>
        </div>

        <div class="form-group">
          <label for="companyName">
            Company
            <span class="red-dot ml-3"></span>
          </label>
          <input
            type="text"
            class="form-control"
            id="companyName"
            name="companyName"
            placeholder="Name"
            autocomplete="off"
            :class="{ 'is-invalid': errors.has('companyName') }"
            v-validate="'required'"
            v-model="bid.company.name"
            :disabled="!isNew"
          />
          <div class="invalid-feedback">The Company field is required</div>
        </div>
        <div class="form-group">
          <div class="form-group">
            <label for="companyEmail">Email</label>
            <input
              type="text"
              class="form-control"
              id="companyEmail"
              name="companyEmail"
              placeholder="Email"
              autocomplete="off"
              v-model="bid.company.email"
              :disabled="!isNew"
            />
          </div>
          <div class="form-group">
            <label for="companyddress">Address</label>
            <input
              type="text"
              class="form-control"
              id="companyddress"
              name="companyddress"
              placeholder="Address"
              autocomplete="off"
              v-model="bid.company.address.street"
              :disabled="!isNew"
            />
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="companyCity">City</label>
              <input
                type="text"
                class="form-control"
                id="companyCity"
                name="companyCity"
                placeholder="City"
                autocomplete="off"
                v-model="bid.company.address.city"
                :disabled="!isNew"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="inputProvince">Province <span class="red-dot ml-3"></span></label>
              <select
                id="inputProvince"
                name="inputProvince"
                class="custom-select"
                :class="{ 'is-invalid': errors.has('inputProvince') }"
                v-model="bid.company.address.provinceId"
                v-validate="'required'"
                data-vv-as="Province"
                :disabled="!isNew"
                :readonly="!isNew"
              >
                <option
                  v-for="province in getProvinces"
                  :key="province.id"
                  :value="province.id"
                >{{ province.name }}</option>
              </select>
              <div class="invalid-feedback">Province is required</div>
            </div>
            <div class="form-group col-md-4">
              <label for="companyPostalCode">Postal Code</label>
              <input
                type="text"
                class="form-control"
                id="companyPostalCode"
                name="companyPostalCode"
                placeholder="Postal Code"
                autocomplete="off"
                v-model="bid.company.address.postalCode"
                :disabled="!isNew"
              />
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="form-group col-md-6">
              <label for="companyPhone">Phone</label>
              <input
                type="text"
                class="form-control"
                id="companyPhone"
                name="companyPhone"
                placeholder="Phone"
                autocomplete="off"
                v-model="bid.company.phone"
                :disabled="!isNew"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="companyFax">Fax</label>
              <input
                type="text"
                class="form-control"
                id="companyFax"
                name="companyFax"
                placeholder="Fax"
                autocomplete="off"
                v-model="bid.company.fax"
                :disabled="!isNew"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="participantType">
            Type
            <span class="red-dot ml-3"></span>
          </label>
          <select
            class="custom-select"
            :class="{ 'is-invalid': errors.has('participantType') }"
            id="participantType"
            name="participantType"
            v-model="bid.participantTypeId"
            v-validate="'required'"
            data-vv-as="Participant Type"
          >
            <option
              v-for="participantType in getParticipantTypes"
              :key="participantType.id"
              :value="participantType.id"
            >{{ participantType.name }}</option>
          </select>
          <div class="invalid-feedback">The Type field is required</div>
        </div>

        <div class="form-group">
          <label for="submittedAmount">Submission Value</label>
          <div class="input-group mb-2">
            <input id="submittedAmount" name="submittedAmount" type="input" class="form-control" autocomplete="off" v-model="bid.submittedAmount" />
          </div>
        </div>

        <div class="form-group">
          <label for="awardedAmount">Awarded Value</label>
          <div class="input-group mb-2">
            <input id="awardedAmount" name="awardedAmount" type="input" class="form-control" autocomplete="off" v-model="bid.awardedAmount" />
          </div>
        </div>

        <div class="form-group form-check">
          <input type="checkbox" class="form-check-input" id="addToFollowerList" v-model="bid.addToFollowerList"
            :readonly="disableAddToFollowerList" :disabled="disableAddToFollowerList" />
          <label class="form-check-label" for="addToFollowerList">Add to Follower List</label>
          <small class="form-text text-muted">This will become disabled if selected company has no users.</small>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-success" @click="onSubmitSaveAdd">Save & Add Another</button>
        <button type="button" class="btn btn-success" @click="onSubmitSaveClose">Save & Close</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import uuid from "uuid/v1";
import cloneDeep from "lodash/cloneDeep";
import Toast from "@/utils/toast";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { CompanyService } from '@/services/'
import debounce from "lodash/debounce";
import AddressDisplayMixin from "@/mixins/addressDisplay";

export default {
  name: "project-bid",
  props: {
    offlineCompanies: { type: Array, required: true }
  },
  components: {
    VueBootstrapTypeahead
  },
  mixins: [AddressDisplayMixin],
  data: function() {
    return {
      companies: [],
      query: '',
      isNew: false,
      bid: {
        addToFollowerList: false,
        awardedAmount: null,
        submittedAmount: null,
        companyId: null,
        company: {
          address: {},
          userCount: 0
        },
        participantTypeId: null
      }
    };
  },
  computed: {
    disableAddToFollowerList () {
      return !this.bid.company.userCount;
    },
    hasCompany () {
      return !!this.bid.company.name;
    },
    ...mapGetters(["getLookup", "getParticipantTypes", "getProjectBid", "getProvinces"])
  },
  mounted: function() {
    this.setup();
  },
  watch: {
    query: debounce(
      async function (newQuery) {
        let companies = await CompanyService.getByTypeahead(decodeURIComponent(newQuery));
        this.companies = companies.data;
      }, 500, false
    )
  },
  methods: {
    setup () {
      this.bid = cloneDeep(this.getProjectBid);
    },
    selectCompany (company) {
      this.isNew = false;
      this.bid.company = company;
      this.bid.addToFollowerList = false;
    },
    clearCompany () {
      this.isNew = false;
      this.query = '';
      this.companies = [];
      this.bid.company = { address: {}, userCount: 0 };
      this.$refs.companyLookup.handleInput('');
      this.$refs.companyLookup.$refs.input.disabled = false;
      this.$validator.reset();
    },
    newCompany () {
      this.isNew = true;
      this.query = '';
      this.companies = [];
      this.bid.company = { id: uuid(), address: {}, userCount: 0 };
      this.$refs.companyLookup.handleInput('');
      this.$refs.companyLookup.$refs.input.disabled = true;
      this.$validator.reset();
    },
    async onSubmitSaveAdd () {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      try {
        await this.submitBid();

        await this.resetProjectBid()
        this.setup();
        this.clearCompany();
        this.$validator.reset();
      }
      catch {
        Toast.danger(this, 'Oops! An error has occured')
      }
    },
    async onSubmitSaveClose () {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      try {
        await this.submitBid();

        this.closeModal();
      }
      catch {
        Toast.danger(this, 'Oops! An error has occured')
      }
    },
    async submitBid () {
      await this.saveProjectBid(cloneDeep(this.bid));

      let message = this.$createElement('span', {}, ['Successfully updated!', this.$createElement('br'), 'Please remember to save your project.']);
      Toast.success(this, [message]);
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-project-bid");
    },
    ...mapActions(["saveProjectBid", "fetchCompany", "resetProjectBid"])
  }
};
</script>
